import React from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../lib/constants";
import Card from "./Card";
import { GameContext } from "./GameContextProvider";

interface CardReceiverProps {
  ascending: boolean;
  index: number;
}

const CardReceiver: React.FC<CardReceiverProps> = ({
  ascending = true,
  index,
}) => {
  const { play, tops, setError, canPlay } = React.useContext(GameContext);

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      drop: (item) => {
        play(item.number, index);
      },
      canDrop: (item: any) => {
        if (!canPlay) {
          setError("Not your turn. Please wait.");
          return false;
        }
        const n = item.number;
        const top = tops[index];
        if (Math.abs(n - top) === 10) {
          setError("Ascenseuuuuur!");
          return true;
        }
        if (ascending && n < top) {
          setError("Can only drop higher cards");
          return false;
        }
        if (!ascending && n > top) {
          setError("Can only drop lower cards");
          return false;
        }
        setError("");
        return true;
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [ascending, tops, index, canPlay]
  );

  return (
    <div
      ref={drop}
      className={`card-container`}
      style={{
        background:
          isOver && canDrop ? "green" : isOver && !canDrop ? "red" : "none",
        border: "dashed magenta",
      }}
    >
      {tops[index] && tops[index] !== 1 && tops[index] !== 100 && (
        <Card number={tops[index]} />
      )}
    </div>
  );
};

export default CardReceiver;
