import React from "react";
import { Button } from "react-bootstrap";
import "./App.css";
import Game from "./components/Game";
import { GameContext } from "./components/GameContextProvider";
import Lobby from "./components/Lobby";

function App() {
  const { room, username, error } = React.useContext(GameContext);
  return (
    <>
      <div className="App">
        {!!error && (
          <div className="row gy-6">
            <Button type="button" className="btn btn-danger">
              {error}
            </Button>
          </div>
        )}

        {(!username || !room) && <Lobby />}
        {username && room && <Game />}
      </div>
    </>
  );
}

export default App;
