import { useDrag } from "react-dnd";
import { ItemTypes } from "../lib/constants";

interface CardProps {
  number: number;
  color?: "red" | "black";
  symbol?: string;
  name?: string;
}

const Card: React.FC<CardProps> = ({
  number,
  color = "black",
  symbol,
  name,
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.CARD,
    item: { number },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    options: { dropEffect: "move" },
  }));

  return (
    <div
      className={`card-container`}
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className={`card-content__inner`}>
        <div className={`card-content-inner__border-top ${color}`}>
          <div className="top-symbol ">
            <p>{number}</p>
          </div>
          <div />
        </div>
        <div className={`card-content-inner__center ${color}`}>
          <p>{number}</p>
        </div>
        <div className={`card-content-inner__border-bottom ${color}`}>
          <div />
          <div className="bottom-symbol ">
            <p>{number}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
