import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Player from ".//Player";
import Board from "./Board";
import { GameContext } from "./GameContextProvider";

const Game: React.FC = () => {
  const { phase, players, canPlay, hand, room, username } =
    React.useContext(GameContext);
  return (
    <>
      <div style={{ color: "white" }}>Table is {phase}</div>
      <div style={{ color: "white" }}>Player is {username}</div>
      <div style={{ color: "white" }}>Room is {room}</div>
      <div style={{ color: "white" }}>
        Can I play? {JSON.stringify(canPlay)}
      </div>
      <div style={{ color: "white" }}>
        Number of players is {players.length}
      </div>
      <DndProvider backend={HTML5Backend}>
        <Board />
        <Player hand={hand} />
      </DndProvider>
    </>
  );
};

export default Game;
