export const ItemTypes = {
  CARD: "card",
};

export interface State {
  players: User[];
  deck: Card[];
  tops: Card[];
  updated_at: number;
  phase: GamePhase;
  turn: number;
  error?: any;
}

export interface User {
  username: string;
  client: string;
  hand: Card[];
}

export type Card = number;

export enum GamePhase {
  OPEN,
  STARTED,
  CLOSED,
}

export interface JoinResponse {
  hand: Card[];
}
