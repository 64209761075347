import React, { FormEvent } from "react";
import { GamePhase, State } from "../lib/constants";

interface GameContextProps {
  room: string | undefined;
  username: string | undefined;
  hand: number[];
  cardsPlayed: number[];
  tops: number[];
  error: string;
  canPlay: boolean;
  phase: GamePhase;
  players: string[];

  join: (event: FormEvent<HTMLFormElement>) => boolean;
  play: (card: number, top_index: number) => void;
  draw: () => void;
  setError: (s: string) => void;
}

export const GameContext = React.createContext<GameContextProps>({
  room: undefined,
  username: undefined,
  hand: [],
  cardsPlayed: [],
  tops: [],
  canPlay: false,
  phase: GamePhase.OPEN,
  players: [],
  error: "",
  join: () => false,
  play: () => {},
  draw: () => {},
  setError: () => {},
});

const GameContextProvider = (props: any) => {
  const [roomid, setRoomId] = React.useState<string | undefined>(undefined);
  const [username, setUsername] = React.useState<string | undefined>(undefined);
  const [hand, setHand] = React.useState<number[]>([]);
  const [cardsPlayed, setCardsPlayed] = React.useState<number[]>([]);
  const [tops, setTops] = React.useState<number[]>([]);
  const [phase, setPhase] = React.useState<GamePhase>(GamePhase.OPEN);
  const [players, setPlayers] = React.useState<string[]>([]);
  const [canPlay, setCanPlay] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = React.useState<number>(0);

  const join = (event: FormEvent<HTMLFormElement>): boolean => {
    event.preventDefault();
    const u = event.currentTarget.username.value;
    const r = event.currentTarget.room.value;

    const payload = {
      action: "join",
      username: u,
    };

    try {
      fetch(`/api/room/${r}`, {
        method: "PUT",
        body: JSON.stringify(payload),
      }).then(async (response) => {
        if (response.ok) {
          console.log("Successfully joined");
          setError("");
          setRoomId(r);
          setUsername(u);
          const rs: State = await response.json();
          setPlayers(rs.players.map((u) => u.username));
          const h = rs.players.find((us) => u === us.username)?.hand;
          if (h) {
            setHand(h);
            setCanPlay(
              rs.players.findIndex((us) => u === us.username) === rs.turn
            );
            setTops(rs.tops);
          } else {
            setError(`Could not retrieve hand for ${u}`);
          }
        } else {
          console.log("Something wrong happened");
          setError(await response.text());
        }
      });
    } catch (err) {
      console.log(err);
      setError(JSON.stringify(err));
    }
    return false;
  };

  // Move card from hand and put it in one of the tops
  const play = (card: number, top_index: number) => {
    if (top_index < 0 || top_index > 3) {
      setError(`Cannot play a card on top ${top_index}`);
      return;
    }

    // Remove card from hand, put in on tops
    setHand((prevHand) => {
      const handIndex = prevHand.findIndex((c) => c === card);
      if (handIndex === -1) {
        setError(`Cannot play ${card}. It's not in your hand.`);
        return prevHand;
      }
      setTops((prevTops) => {
        const newTops = prevTops.map((v, i) => (i === top_index ? card : v));
        return newTops;
      });
      setCardsPlayed((prevCardsPlayed) => [...prevCardsPlayed, card]);

      return prevHand.filter((c) => c !== card);
    });

    // Updating remote state
    const payload = {
      action: "play",
      username,
      card,
      index: top_index,
    };
    fence(() =>
      fetch(`/api/room/${roomid}`, {
        method: "PUT",
        body: JSON.stringify(payload),
      }).then(async (response) => {
        if (!response.ok) {
          const err = await response.text();
          setError(err);
        }
      })
    );
  };

  const refresh = React.useCallback(() => {
    if (!roomid) {
      console.log("no room id, no refresh");
      return;
    }

    console.log("Invoked refresh");
    fence(() =>
      fetch(`/api/room/${roomid}`).then(async (response) => {
        if (response.ok) {
          const s: State = await response.json();
          setLastUpdate((prevLastUpdate) => {
            if (s.updated_at <= prevLastUpdate) {
              console.log(`Nothing to refresh`);
              return prevLastUpdate;
            }

            // TODO: Reset hand from state
            setTops((_) => s.tops);
            setPhase(s.phase);
            setPlayers(s.players.map((u) => u.username));
            setCanPlay(
              s.players.findIndex((u) => u.username === username) === s.turn
            );

            return s.updated_at;
          });
        }
      })
    );
  }, [roomid]);

  React.useEffect(() => {
    const interval = setInterval(refresh, 5000);
    console.log(`Hooking refresh ${interval}`);
    return () => {
      console.log(`Unhook refresh ${interval}`);
      clearInterval(interval);
    };
  }, [refresh]);

  const draw = () => {
    if (!canPlay) {
      setError("You cannot draw if it's not your turn");
      return;
    }

    // Updating remote state
    const payload = {
      action: "draw",
      username,
      played: cardsPlayed,
    };
    fence(() =>
      fetch(`/api/room/${roomid}`, {
        method: "PUT",
        body: JSON.stringify(payload),
      }).then(async (response) => {
        if (response.ok) {
          const rs: State = await response.json();
          const h = rs.players.find((us) => username === us.username)?.hand;
          if (h) {
            setHand(h);
            setCanPlay(false);
            setCardsPlayed([]);
          }
        } else {
          const err = await response.text();
          setError(err);
        }
      })
    );
  };

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const fence = async (f: () => void) => {
    while (isUpdating) {
      await sleep(1000);
    }
    setIsUpdating(true);
    f();
    setIsUpdating(false);
  };

  const context = {
    room: roomid,
    username,
    hand,
    cardsPlayed,
    tops,
    error,
    canPlay,
    phase,
    players,
    join,
    play,
    draw,
    setError,
  };

  return (
    <GameContext.Provider value={context}>
      {props.children}
    </GameContext.Provider>
  );
};

export default GameContextProvider;
