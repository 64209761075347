import React from "react";
import Card from "./Card";
interface PlayerProps {
  hand: Array<number> | undefined;
}

const Player: React.FC<PlayerProps> = ({ hand }) => {
  return (
    <>
      <div className="hand">
        {hand?.map((v) => (
          <Card key={v} number={v} />
        ))}
      </div>
    </>
  );
};

export default Player;
