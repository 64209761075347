import React from "react";
import { Button, Form } from "react-bootstrap";
import { GameContext } from "./GameContextProvider";

// interface LobbyProps {
//   setUsername: (s: string) => void;
//   setRoom: (s: string) => void;
// }

const Lobby: React.FC = () => {
  const { join } = React.useContext(GameContext);
  // const [error, setError] = React.useState<string>("");

  return (
    <div className="App col">
      <div className="row gy-6">
        <Form onSubmit={join}>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label style={{ color: "white" }}>Username</Form.Label>
            <Form.Control type="text" placeholder="Enter username" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="room">
            <Form.Label style={{ color: "white" }}>Room id</Form.Label>
            <Form.Control type="text" placeholder="Enter room to join" />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};
export default Lobby;
