import React from "react";
import Button from "react-bootstrap/Button";
import Card from "./Card";
import CardReceiver from "./CardReceiver";
import { GameContext } from "./GameContextProvider";

const Board: React.FC = () => {
  const { draw } = React.useContext(GameContext);

  return (
    <div className="play-board">
      <>
        <div className="board">
          <Card key="asc1" number={1} />
          <CardReceiver ascending={true} index={0} />
          <Card key="asc2" number={1} />
          <CardReceiver ascending={true} index={1} />
          <Card key="desc1" number={100} />
          <CardReceiver ascending={false} index={2} />
          <Card key="desc2" number={100} />
          <CardReceiver ascending={false} index={3} />
        </div>
        <div className="d-grid gap-2">
          <Button variant="primary" size="lg" onClick={draw}>
            Draw!
          </Button>
        </div>
      </>
    </div>
  );
};

export default Board;
